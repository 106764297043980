import React from "react";
import styled from "styled-components";

const Stage = styled.div`
  height: 295px;
  width: 295px;

  perspective: 1600px;
  perspective-origin: 90% -240px;
`;

const CubeContainer = styled.div`
  @keyframes rotate {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }
    100% {
      transform: rotateX(0deg) rotateY(360deg);
    }
  }

  transform-style: preserve-3d;
  animation: rotate 5s infinite linear;
`;

const CubeFace = styled.figure`
  transform-origin: 50% 50% 0;
  height: 295px;
  width: 295px;
  margin: auto;
  display: block;
  position: fixed;
  opacity: 0.5;
  border-radius: 3px;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    p {
      font-family: DotGothic16;
      text-align: center;
      font-size: 10vh;
      font-weight: bold;
      color: ${(props) => props.textColor || "white"};
    }
  }
`;

const FrontFace = styled(CubeFace)`
  transform: translateZ(150px);
  background-color: rgb(115, 243, 115);
`;

const BackFace = styled(CubeFace)`
  transform: rotateY(180deg) translateZ(150px);
  background-color: #efe7b1;
`;

const TopFace = styled(CubeFace)`
  transform: rotateX(90deg) translateZ(150px);
  background-color: rgb(233, 125, 125);
`;

const LeftFace = styled(CubeFace)`
  transform: rotateY(-90deg) translateZ(150px);
  background-color: #6acaa4;
`;

const BottomFace = styled(CubeFace)`
  transform: rotateX(-90deg) translateZ(150px);
  background-color: rgb(207, 207, 151);
`;

const RightFace = styled(CubeFace)`
  transform: rotateY(90deg) translateZ(150px);
  background-color: #a5cde0;
`;

const FM = () => (
  <div>
    <p>fm</p>
  </div>
);

const Cube = ({ size }) => {
  return (
    <Stage size={size}>
      <CubeContainer>
        <FrontFace textColor="blue">
          <FM />
        </FrontFace>
        <TopFace />
        <BottomFace />
        <LeftFace textColor="yellow">
          <FM />
        </LeftFace>
        <RightFace textColor="red">
          <FM />
        </RightFace>
        <BackFace textColor="green">
          <FM />
        </BackFace>
      </CubeContainer>
    </Stage>
  );
};

export default Cube;
