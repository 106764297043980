import styled from "styled-components";
import "./App.css";
import Cube from "./Cube";
import Nav from "./Nav";

const Header = styled.h1`
  font-family: DotGothic16;
  letter-spacing: 5px;

  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.1em solid transparent; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 1.5s steps(15, end), blink-caret 0.75s step-end 8;
  /* The typing effect */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 38%;
    }
  }
`;

const Main = () => (
  <>
    <Header>frank meszaros</Header>
    <div style={{ marginTop: 32, marginBottom: 96 }}>
      <Cube />
    </div>
    <Nav />
  </>
);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Main />
      </header>
    </div>
  );
}

export default App;
