import React from "react";
import styled from "styled-components";

const NAV_ITEMS = [
  { title: "About", href: "/about" },
  //   { title: "Experiences", href: "/experiences" },
  //   { title: "Skills", href: "/skills" },
  { title: "Contact", href: "/contact" },
];

const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 2vh;
  font-family: "DotGothic16";
`;

const NavItem = styled.div`
  padding: 20px;
  letter-spacing: 2px;

  a {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    background-color: #00ff00;
  }
`;

const Nav = () => {
  return (
    <NavBar>
      {NAV_ITEMS.map((nav) => (
        <NavItem key={nav.title}>
          <a href={nav.href}>{nav.title}</a>
        </NavItem>
      ))}
    </NavBar>
  );
};

export default Nav;
